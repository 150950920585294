
import PuDate from './PuDate.vue'
import { Component } from '~/utility/pu-class-decorator'

@Component
export default class PuUtcDate extends PuDate {
  get formattedDate() {
    if (!this.date) return ''
    return Intl.DateTimeFormat(this.$i18n.locale, {
      ...this.option,
      timeZone: 'UTC',
    }).format(new Date(this.date))
  }
}
