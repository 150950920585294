
import { Component, Prop, Vue, Watch } from '~/utility/pu-class-decorator'

@Component
export default class NumberStepper extends Vue {
  @Prop({ required: true, type: Number })
  value: number

  @Prop({ required: false, type: Boolean, default: false })
  disabled: boolean

  @Prop({ required: false, type: String })
  name: string

  @Prop({ required: false, type: Number, default: 1 })
  min: number

  @Prop({ required: false, type: Number, default: Infinity })
  max: number

  @Prop({ required: false, type: Number, default: 1 })
  defaultValue: number

  currentValue: number = 0

  beforeMount() {
    let value = this.value ? +this.value : +this.defaultValue
    const correctedValue = this.validateInputValue(value)
    if (value !== correctedValue) {
      value = correctedValue
      this.$emit('input', value)
    }

    this.currentValue = value
  }

  get isDecreaseButtonDisabled() {
    const min = +this.min
    const currentValue = +this.currentValue
    return min === currentValue || currentValue < min || this.disabled
  }
  get isIncreaseButtonDisabled() {
    const max = +this.max
    const currentValue = +this.currentValue
    return max === currentValue || currentValue > max || this.disabled
  }

  @Watch('value')
  onValueChanged(newValue: number | string) {
    if (newValue !== '') {
      newValue = this.validateInputValue(+newValue)
      if (newValue !== this.currentValue) {
        this.currentValue = Number(newValue)
      }
    }
  }

  validateInputValue(value) {
    if (Number.isNaN(value)) {
      value = this.min
    } else {
      value = Math.max(this.min, value)
      value = Math.min(this.max, value)
    }
    return Math.floor(value)
  }

  onInputChange(event) {
    const { value } = event.target
    this.currentValue = value
      ? this.validateInputValue(+value)
      : this.currentValue
    this.emitInputChange()
  }

  doStep(type) {
    if (
      (this.isDecreaseButtonDisabled && type === 'decrease') ||
      (this.isIncreaseButtonDisabled && type === 'increase')
    ) {
      this.$emit('overlimit', type)
      return
    }
    const currentValue = +this.currentValue
    this.currentValue =
      type === 'decrease' ? currentValue - 1 : currentValue + 1
    this.emitInputChange(type)
    this.$emit(type)
  }

  emitInputChange(type?: string) {
    this.$emit('input', this.currentValue)
    this.$emit('change', this.currentValue)
    if (type) {
      this.$emit('changeType', { type, currentValue: this.currentValue })
    }
  }
}
