import 'reflect-metadata'

import { Container, inject, injectable } from 'inversify'

export const rootContainer: Container = new Container({
  autoBindInjectable: true,
  skipBaseClassChecks: true,
})

// rexport so we import from here for the global reflect-metadata
export { inject, injectable }
