
import { Component, Mixins } from '~/utility/pu-class-decorator'
import UserDetailsMixin from '~/mixins/UserDetailsMixin'

@Component
export default class UserAccountUsersInfo extends Mixins(UserDetailsMixin) {
  user = {
    id: 123,
    country_code: 'GB',
  }
}
