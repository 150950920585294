
import { Component, Prop, Vue, Watch } from '~/utility/pu-class-decorator'

@Component
export default class UiCollapse extends Vue {
  active = false
  index: number

  @Prop({ default: false })
    selected: boolean

  @Prop({ default: false })
    disabled: boolean

  @Watch('selected')
  onSelectedChange(selected: boolean) {
    this.active = selected
  }

  @Watch('active')
  onActiveChange(active: boolean) {
    active
      ? this.$emit('collapse-open', this.index)
      : this.$emit('collapse-close', this.index)
  }

  created() {
    this.index = Date.now()
  }

  mounted() {
    this.$nextTick(() => {
      if (this.active !== this.selected) {
        this.active = this.selected
      }
    })
    if (this.active) {
      this.$emit('collapse-open', this.index)
    }
  }

  toggle() {
    this.active = !this.active
  }
}
