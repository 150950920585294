
import { AppMenuMixin } from '../../apps/app-menu/mixin'
import type { PendingReview } from '../../apps/user-reviews/pending-review/schemas'
import { Component, Mixins, Prop } from '~/utility/pu-class-decorator'
import AuthMixin from '~/mixins/AuthMixin'

@Component
export default class AccountMenu extends Mixins(AuthMixin, AppMenuMixin) {
  @Prop()
    pendingReviews: PendingReview[]

  // eslint-disable-next-line require-await
  async doLogout() {
    this.$isDesktop
      ? this.logOut(this.$puLocalePath({ name: 'index' }))
      : this.logOut()
  }

  get contactUsLink() {
    return `https://www.pitchup.com/${
      this.$i18n.locale !== 'en-gb' ? this.$i18n.locale + '/' : ''
    }about/contact/`
  }
}
