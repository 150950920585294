import { render, staticRenderFns } from "./account-menu.vue?vue&type=template&id=4e9b1cf6&scoped=true"
import script from "./account-menu.vue?vue&type=script&lang=ts"
export * from "./account-menu.vue?vue&type=script&lang=ts"
import style0 from "./account-menu.vue?vue&type=style&index=0&id=4e9b1cf6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e9b1cf6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UserAccountUsersInfo: require('/app/components/users/UserAccountUsersInfo.vue').default,Translate: require('/app/components/Translate.vue').default})
