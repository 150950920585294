
import { Component, Prop, Vue } from '~/utility/pu-class-decorator'

const dateFormatsMap: Record<string, Intl.DateTimeFormatOptions> = {
  day: { day: 'numeric' },
  month: { month: 'long' },
  year: { year: 'numeric' },
  fullDateTime: {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
  },
  dayMonth: { month: 'short', day: 'numeric' },
  full: { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' },
  long: { weekday: 'short', day: 'numeric', month: 'long', year: 'numeric' },
  short: { weekday: 'short', day: 'numeric', month: 'short', year: '2-digit' },
  noYear: { weekday: 'short', day: 'numeric', month: 'short' },
  noDay: { month: 'short', year: 'numeric' },
  time: { hour: 'numeric', minute: 'numeric' },
  numeric: { day: 'numeric', month: 'numeric', year: 'numeric' },
}

@Component
export default class PuDate extends Vue {
  @Prop()
  date: string

  @Prop()
  tag: string

  @Prop()
  options: Record<string, Intl.DateTimeFormatOptions>

  get datetime() {
    if (!this.date) return
    return new Date(this.date).toISOString()
  }

  get formattedDate() {
    if (!this.date) return ''
    return Intl.DateTimeFormat(this.$i18n.locale, this.option).format(
      new Date(this.date),
    )
  }

  get option() {
    return typeof this.options === 'string'
      ? dateFormatsMap[this.options]
      : this.options
  }
}
