import { render, staticRenderFns } from "./UserAccountUsersInfo.vue?vue&type=template&id=bf8d0738&scoped=true"
import script from "./UserAccountUsersInfo.vue?vue&type=script&lang=ts"
export * from "./UserAccountUsersInfo.vue?vue&type=script&lang=ts"
import style0 from "./UserAccountUsersInfo.vue?vue&type=style&index=0&id=bf8d0738&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf8d0738",
  null
  
)

export default component.exports