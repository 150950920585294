export interface Logger {
  push: (event: { [key: string]: string | number | boolean }) => void
}

export const useGtmLogRequest = (requestId, logger?: Logger) => {
  if (
    !logger ||
    typeof window === 'undefined' ||
    !window.performance ||
    (window.navigator && window.navigator.userAgent.includes('gha2spon'))
  ) {
    if (!logger) {
      // console.warn('No logger provided')
    }

    if (process.server) {
      return {
        markEnd() {
          if (logger) {
            logger.push({
              event: 'network-request-made',
              isSSR: true,
              requestId: requestId,
            })
          }
          return
        },
        markStart() {
          return
        },
      }
    }

    return {
      markEnd() {
        return
      },
      markStart() {
        return
      },
    }
  }

  function markStart() {
    window.performance.mark(`${requestId}-started`)
  }

  function markEnd() {
    window.performance.mark(`${requestId}-complete`)

    const loadDuration = window.performance.measure(
      `${requestId}-duration`,
      `${requestId}-started`,
      `${requestId}-complete`,
    )

    logger?.push({
      event: 'network-request-made',
      networkEffectiveType: window.navigator.connection?.effectiveType,
      requestDuration: loadDuration?.duration,
      requestId: requestId,
      // @ts-ignore
      isDesktop:
        // @ts-ignore
        typeof window.isDesktop !== 'undefined'
          ? // @ts-ignore
          window.isDesktop
          : !navigator.userAgent.includes('Mobile'),
    })
  }

  return {
    markEnd,
    markStart,
  }
}
