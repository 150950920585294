
import { Component, Prop, Vue } from '~/utility/pu-class-decorator'

const STROKE_COLOUR = 'var(--colour-mid-green)'

@Component({
  inheritAttrs: false,
})
export default class LoadingSpinner extends Vue {
  @Prop({ default: STROKE_COLOUR })
    strokeColor: string
}
